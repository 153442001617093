
//== Image Path
$img-path:          "../../../assets/img";

//== Asset Path
$asset-path:          "/files";

//== Font Path
$font-path:          "../../../assets/fonts";
$fa-font-path:      $font-path;


//== Colors
// blue
$color-a-lighter:   #e0eaf5;
$color-a-light:     #8fb2d9;
$color-a:           #2f6296;

// grey
$color-b-light:     #b1b3b4;
$color-b:           #868889;
$color-b-dark:      #323232;
$color-b-darker:    #000;

// green
$color-c:           #50a033;


$color-primary:     $color-a;
$color-secondary:   $color-b;

$font: "Lato", Helvetica, sans-serif;

$fs-1: rem(30);
$fs-2: rem(22);
$fs-3: rem(18);
$fs-4: rem(16);
$fs-5: rem(15);
$fs-6: rem(14);
$fs-7: rem(13);
$fs-base: $fs-5;
$lh-base: 1.533;

$spacing: $lh-base * rem(16);
$spacing-half: $spacing / 2;

//== Neat Variables
$visual-grid: false !global;
$visual-grid-color: #eee !global;
$visual-grid-index: back !global;
$visual-grid-opacity: .2 !global;
$max-width: em(1170, 15) !global;

//== Bootstrap Variables
$brand-primary: $color-primary;

$font-family-base: $font;
$font-size-base: 15px;
$line-height-base: $lh-base;
$text-color: $color-b-dark;

$grid-gutter-width: 60px !default;

$container-large-desktop: (1080px + $grid-gutter-width);

$grid-float-breakpoint: 992px; // > $screen-md-min
