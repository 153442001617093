.navbar-meta {
    padding-top: 10px;
    background-color: #fff;
    > .container {
    }
}
.nav-meta-list {
    @include reset-list;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: $fs-7;
    font-weight: 400;
    line-height: 1;
    text-align: right;
    > li {
        display: inline-block;

        > a {
            padding: 0 10px;
            color: $color-a;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    > li + li {
        border-left: 1px solid #fff;
    }
}

.navbar-top {
    position: relative;
    background-color: $color-a-lighter;

    .navbar-toggle {
        margin-right: 0;
        margin-top: 30px;
        border-color: $color-primary;
        &:hover,
        &:focus {
            background-color: $color-primary;
            .icon-bar {
                background-color: #fff;
            }
        }
        .icon-bar {
            background-color: $color-primary;
        }
    }
}
.navbar-header,
.navbar-brand {
    @media (min-width: $grid-float-breakpoint) {
        float: none;
        text-align: center;
    }
}
.navbar-header {
    margin-top: -10px;
    margin-bottom: -10px;
}
.navbar-brand {
    display: block;
    float: none;
    width: 120px;
    height: 120px;
    padding: 0;
    background-color: #fff;
    background-image: url("#{$img-path}/logo.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 80%;
    border: 1px solid #b5b5b5;
    text-indent: -10000em;
    @media (min-width: $screen-md-min) {
        width: 100%;
        height: 200px;
        background-size: auto;
    }
    &:hover {
        color: #fff;
    }
}
.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
}
.nav-main {
    @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 20px;
    }
}
.nav-main-list {
    @include reset-list;
    > li {
        display: block;
        > a {
            display: block;
            color: $color-a;
            font-size: $fs-2;
            &:hover,
            &.active {
                color: $color-a;
                text-decoration: underline;
            }
        }
    }
    @media (max-width: $grid-float-breakpoint-max) {
        > li {
            display: block;
            > a {
                padding: 10px 30px;
                line-height: 1;
                .fa {
                    float: left;
                    margin-left: -30px;
                }
            }
        }
    }
    @media (min-width: $grid-float-breakpoint) {
        display: table;
        width: 100%;

        > li {
            display: table-cell;
            text-align: left;
            white-space: nowrap;


            .fa {
                display: inline-block;
                margin-right: 10px;
                font-size: $fs-2;
                text-align: center;
                opacity: .3;
            }
            > a {
                font-size: $fs-3;
                font-weight: 400;
                text-transform: uppercase;

                &:hover,
                &.active {
                    text-decoration: underline;

                    .fa {
                        opacity: 1;
                    }
                }
            }

            > ul {
                @include reset-list;
                position: absolute;
                top: 100%;
                left: 20px;
                z-index: 100;
                padding-top: rem(15);
                padding-bottom: rem(15);
                font-size: $fs-7;
                line-height: 1;
                text-align: left;
                > li {
                    display: inline-block;
                }
                a {
                    padding: 0 10px;
                    color: $color-b-dark;
                    &:hover,
                    &.active {
                        color: $color-primary;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

