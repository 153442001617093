html {
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    /* -webkit-font-smoothing: none; */
}

body {
    background-color: $color-a-lighter;
    font-size: $fs-base;
}


.global-header {
}
.global-footer {
    padding: rem(60) rem(20) rem(100) rem(20);
}
.header-images {
    margin-top: 16px;
    margin-bottom: 10px;
    height: 120px;
    overflow: hidden;
    > img {
        display: inline-block;
        max-width: 33%;
        max-height: 100%;
    }
    @media (max-width: $grid-float-breakpoint-max) {
        display: none;
    }
}
main {
    padding-top: rem(100);
    background-color: #fff;
    > .container {
        padding-bottom: $grid-gutter-width;
    }
    @media (max-width: $grid-float-breakpoint-max) {
        padding-top: rem(10);
    }
}

