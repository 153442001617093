h1 {
    margin-bottom: rem(12);
    color: $color-a;
    font-size: $fs-3;
    font-weight: 900;

    @media (min-width: $screen-md-min) {
        .body > &:first-child {
            margin-top: 0;
        }
    }
}
h2 {
    margin-top: rem(32);
    margin-bottom: 0;
    color: $color-c;
    font-size: $fs-4;
    font-weight: 900;
}
h3 {
    font-size: $fs-5;
    font-weight: 400;
}
h4 {
    font-size: $fs-6;
    font-weight: 700;
}
h5 {
    font-size: $fs-7;
    font-weight: 700;
}

p {
    margin: 0 0 $spacing;
}
p code {
	background: $color-b-dark;
	padding: 1px 5px;
	border-radius: 3px;
    color: $color-b-light;
    line-height: 2.0;
}
code, kbd, pre, samp {
    font-family: Menlo, Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: rem(12);
}

blockquote {
    margin: $spacing-half 0 $spacing;
	padding: $spacing-half $spacing;
	background: $color-primary;
	border-radius: 3px;
	color: #fff;
	p:last-child {
		margin: 0;
	}
	a {
		color: #fff;
	}
}
