.slice {
    margin: rem(16) 0;
    strong, b {
        color: $color-b-darker;
    }
    a {
        font-style: italic;
        text-decoration: underline;
    }
}

.side-image {

    @media (min-width: $grid-float-breakpoint) {
        h1:first-child,
        h2:first-child {
            margin-top: 0;
        }
    }
}

.logged-in {
    padding: 20px 0 10px 0;
    background-color: $color-a;
    color: $color-a-lighter;
    text-align: center;

    a {
        color: #fff;
        font-weight: 700;
    }
}

.yform {
    @media (min-width: $grid-float-breakpoint) {
        padding-left: 380px;
    }

}

.svg-map a:hover {
    cursor: pointer;
    .map {
        fill: #fdcf65;
    }
}

.map_canvas {
    display: block;
    position: relative;
    width: 100%;
    height: 400px;
}

.video-container {
    position: relative;
    margin-bottom: ($spacing);
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
}
